.markdown-editor {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.markdown-input {
  width: 100%;
  height: 300px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  background: transparent;
  color: transparent;
  caret-color: black;
  z-index: 1;
  position: relative;
}

.markdown-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  pointer-events: none;
  overflow: auto;
  white-space: pre-wrap;
  color: black;
}

.markdown-input:focus {
  outline: none;
}

.markdown-editor::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: -1;
}
